import { InputText as InputTextAtom } from '@/components/atoms/Input'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const InputText = (props) => {
  const { name, label, icon, variant, height, width, readOnly, disabled, className } = props

  return (
    <div className={`${styles.input} ${styles[variant]} ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      {icon
        ? (
          <span className='p-input-icon-left'>
            <i className={icon} />
            <InputTextAtom
              {...props}
              invalidClassName={styles.invalid}
              invalidMessageClassName={styles.invalid_message}
              className={`${readOnly && styles.readonly} ${disabled && styles.disabled}`}
              style={{ height, ...width }}
            />
          </span>
          )
        : (
          <InputTextAtom
            {...props}
            invalidClassName={styles.invalid}
            invalidMessageClassName={styles.invalid_message}
            className={`${readOnly && styles.readonly} ${disabled && styles.disabled}`}
            style={{ height, ...width }}
          />
          )}
    </div>
  )
}

InputText.defaultProps = {
  name: '',
  label: '',
  variant: 'primary',
  readOnly: false,
  disabled: false,
  className: ''
}

InputText.propTypes = {
  invalidClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
}
